import { render, staticRenderFns } from "./MantraList.vue?vue&type=template&id=642ba65c&scoped=true&"
import script from "./MantraList.vue?vue&type=script&lang=js&"
export * from "./MantraList.vue?vue&type=script&lang=js&"
import style0 from "./MantraList.vue?vue&type=style&index=0&id=642ba65c&lang=scss&scoped=true&"
import style1 from "./MantraList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642ba65c",
  null
  
)

export default component.exports