<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >

    <div v-if="mantras !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-2"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-start mb-2 mb-md-0"
            >
              <h2>فیلتر های جستجو :</h2>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش :</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>وضعیت :</label>
              <v-select
                  v-model="IsActive"
                  :options="perStatusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50 w-50"
              />
            </b-col>

            <!-- Field:  publishDate -->
            <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>تاریخ شروع انتشار :</label>
              <input
                  type="text"
                  :id="`start${1}`"
                  placeholder="شروع انتشار"
                  :value="createJalaliDate(publishDate)"
                  class="form-control w-75 mx-auto mb-1 text-sm block rounded-full text-center bg-gray-100 border-transparent placeholder-inputPlaceHolder text-gray-700"
              />
              <custom-date-picker
                  v-model="publishDate"
                  auto-submit
                  input-format="YYYY-MM-DD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  display-format="jYYYY/jMM/jDD HH:mm"
                  class=""
                  :element="`start${1}`"
                  type="datetime"
                  color="#5c6bc0"
                  :min="new Date()"
              ></custom-date-picker>
            </b-col>

            <!-- Field:  publishDate -->
            <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>تاریخ اتمام انتشار :</label>
              <input
                  type="text"
                  :id="`end${1}`"
                  placeholder="اتمام انتشار"
                  :value="createJalaliDate(endDate)"
                  class="form-control w-75 mx-auto mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent placeholder-inputPlaceHolder text-gray-700"
              />
              <custom-date-picker
                  v-model="endDate"
                  auto-submit
                  input-format="YYYY-MM-DD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  display-format="jYYYY/jMM/jDD HH:mm"
                  class=""
                  :element="`end${1}`"
                  type="datetime"
                  color="#5c6bc0"
                  :min="new Date()"
              ></custom-date-picker>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12">
            <b-row>
              <b-card
                  v-for="(mantra,idx) in mantras"
                  tag="article"
                  class="col-4 box-shadow-0 px-1 bg-transparent"
                  no-body
              >
                <b-link to='#'>
                  <b-img
                      style="max-height: 30rem"
                      :src="'https://api.maxterms.com/media/gallery/mantra/'+mantra.mediaFile.base64"
                      class="card-img-top"
                  />
                </b-link>
                <b-card-body class="px-2 bg-white rounded-bottom">
                  <b-card-title class="my-1">
                    <b-link
                        to="#"
                        class="my-1 blog-title-truncate text-body-heading"
                    >
                      {{ mantra.title }}
                    </b-link>
                    <b-badge
                        pill
                        v-if="mantra.isActive"
                        class="mr-75"
                        variant="success"
                    >
                      فعال
                    </b-badge>
                    <b-badge
                        pill
                        v-else
                        class="mr-75"
                        variant="danger"
                    >
                      غیر فعال
                    </b-badge>
                  </b-card-title>
                  <b-media no-body>
                    <b-media-body class="">
                      <feather-icon
                          icon="CalendarIcon"
                          class="mr-50"
                      />
                      <small>شروع انتشار : </small>
                      <small class="text-muted">
                        {{ mantra.publishDate.slice(11, 16) }}
                        -
                        {{ createJalaliDate(mantra.publishDate.slice(0, 10)) }}
                      </small>
                      <span class="text-muted ml-75 mr-50">|</span>
                      <small>اتمام انتشار : </small>
                      <small class="text-muted">
                        {{ mantra.endTime.slice(11, 16) }}
                        -
                        {{ createJalaliDate(mantra.endTime.slice(0, 10)) }}
                      </small>
                    </b-media-body>
                  </b-media>
                  <b-card-text class=" mt-1 item-description">
                    {{ mantra.summary }}
                  </b-card-text>
                  <hr>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-1">
                      <b-link
                          class="font-weight-bold text-danger mr-1"
                          v-b-modal.modal-delete
                          @click="showModal(mantra.mantraId)"
                      >
                        <feather-icon
                            icon="TrashIcon"
                            class="mr-25"
                        />
                        حذف
                      </b-link>
                      <b-link
                          :to="{ name: 'apps-mantra-edit', params: { id: mantra.mantraId } }"
                          class="font-weight-bold"
                      >
                        <feather-icon
                            icon="EditIcon"
                            class="mr-25"
                        />
                        ویرایش
                      </b-link>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-row>
          </b-col>

          <b-col
              v-if="!mantras || mantras.length === 0"
              cols="12"
              class="d-flex align-items-center justify-content-center"
          >
            <h3>هیچ آیتمی یافت نشد !</h3>
          </b-col>
        </b-row>
      </div>


      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalCounts"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon style="transform: rotate(180deg)"
                              icon="ChevronLeftIcon"
                              size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Delete Modal  -->
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteMantra(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BImg, BModal , BCardBody , BCardText , BCardTitle , BMediaBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {PaymentGetAllRequest} from "@/libs/Api/Payment";
import Helper from "@/libs/Helper";
import {MantraGetAllFilterRequest, MantraDeleteRequest} from "@/libs/Api/Mantra";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  title: "لیست آیتم های مانترا - پنل ادمین مکس ",
  name: "MantraList",
  data() {
    return {
      mantras: null,
      totalCounts: null,
      currentPage: 1,
      perPage: 9,
      IsActive: '',
      publishDate: '',
      endDate: '',
      overlay: false,
      perPageOptions: [9, 20, 30, 40, 50],
      perStatusOptions: [
        {label: 'همه', value: ''},
        {label: 'فعال', value: true},
        {label: 'غیر فعال', value: false},
      ],
      deleteItem: null,
    }
  },
  async created() {
    await this.getAllMantra(this.perPage, this.currentPage, this.IsActive, this.publishDate, this.endDate)
  },
  methods: {
    async getAllMantra(count, pageNumber, IsActive, publishDate, endDate) {
      let _this = this;
      _this.overlay = true;
      let data = {
        pageNumber: pageNumber,
        count: count,
        IsActive: IsActive,
        publishDate: publishDate,
        endDate: endDate,
      }

      let mantraGetAllFilterRequest = new MantraGetAllFilterRequest(_this);
      mantraGetAllFilterRequest.setParams(data);
      await mantraGetAllFilterRequest.fetch(function (content) {
        _this.overlay = false;
        _this.mantras = content.mantras;
        _this.totalCounts = content.mantrasCount;
      }, function (error) {
        _this.overlay = false;
        console.log(error);
      })
    },
    async deleteMantra(param) {
      let _this = this;

      let data = {
        id: param
      }

      let mantraDeleteRequest = new MantraDeleteRequest(_this);
      mantraDeleteRequest.setParams(data);
      await mantraDeleteRequest.fetch(function (content) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `عملیات موفق`,
                icon: 'CheckIcon',
                variant: 'success',
                text: `مانترا حذف شد.`,
              },
            })
            _this.getAllMantra(this.perPage, this.currentPage, this.IsActive, this.publishDate, this.endDate);
          },
          function (error) {
            console.log(error)
          })
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
  },
  watch: {
    perPage: function (nv, ov) {
      this.getAllMantra(nv, this.currentPage, this.IsActive, this.publishDate, this.endDate);
    },
    currentPage: function (nv, ov) {
      this.getAllMantra(this.perPage, nv, this.IsActive, this.publishDate, this.endDate);
    },
    IsActive: function (nv, ov) {
      this.getAllMantra(this.perPage, this.currentPage, nv, this.publishDate, this.endDate);
    },
    publishDate: function (nv, ov) {
      this.getAllMantra(this.perPage, this.currentPage, this.IsActive, nv, this.endDate);
    },
    endDate: function (nv, ov) {
      this.getAllMantra(this.perPage, this.currentPage, this.IsActive, this.publishDate, nv);
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    vSelect,
    BImg,
    BModal,
    BCardBody , BCardText , BCardTitle , BMediaBody
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
